import loadable from '@loadable/component';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getConfigByKey } from '../tenantConfig/services';
import { DivStyled } from './styles/styled';

const FooterSctv = loadable(() => import('./footerSctv'));
const FooterOdv = loadable(() => import('./footerOdv'));
const FooterW = loadable(() => import('./footerW'));

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = { footer: true };
  }

  componentDidMount() {
    const { block, location } = this.props;

    block.forEach(element => {
      if (
        location.pathname.split('/')[1] === element ||
        location.pathname.split('/')[2] === element
      ) {
        this.setState({
          footer: false,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (__SERVER__) {
    //   return;
    // }
    const { block } = this.props;
    const { pathname } = nextProps.location;
    block.every(element => {
      if (pathname.split('/')[1] === element || pathname.split('/')[2] === element) {
        this.setState({
          footer: false,
        });
        return false;
      } else {
        this.setState({
          footer: true,
        });
        return true;
      }
    });
  }

  render() {
    const { footer } = this.state;
    if (!footer) return null;
    let FooterComp;
    switch (getConfigByKey('tenant_slug')) {
      case 'sctv':
        FooterComp = FooterSctv;
        break;
      case 'projectw':
        FooterComp = FooterW;
        break;
      default:
        FooterComp = FooterOdv;
    }
    return (
      <DivStyled>
        <FooterComp />
      </DivStyled>
    );
  }
}
const mapStateToProps = state => ({
  documents: state.global.documents,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer)));
